import { render, staticRenderFns } from "./ShipPayments.vue?vue&type=template&id=09029d5e&scoped=true&"
import script from "./ShipPayments.vue?vue&type=script&lang=js&"
export * from "./ShipPayments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09029d5e",
  null
  
)

export default component.exports