<template>
    <div>
        <h1>Shipping Paid</h1>
        <div class="panel panel-default toggle_container">
            <div class="panel-heading"></div>
            <div>
                <form @submit.prevent="runReport">
                    <div class="row panel-body">
                        <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                            <label>Start Date</label>
                            <DatePicker v-model="optionFields.startDate.val" :input-attributes="{class: 'form-control'}" />
                        </div>
                        <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                            <label>End Date</label>
                            <DatePicker v-model="optionFields.endDate.val" :input-attributes="{class: 'form-control'}" />
                        </div>
                        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                            <label>CDI Location</label>
                            <select class="form-control" v-model="optionFields.cdiLocation.val">
                                <option value="0">All</option>
                                <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                            </select>
                        </div>
                        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                            <label>Shipper</label>
                            <Typeahead cdi_type="shipping_co" v-bind:ID.sync="optionFields.shipperId.val" />
                        </div>
                        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                            <label>Paid</label>
                            <select v-model="optionFields.paid.val" class='form-control'>
                                <option value='-1'>All</option>
                                <option value='1'>Paid</option>
                                <option value='0'>Unpaid</option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <Results @clicked="checkItem" @enterKeyPressed="changeFreight" />
        <input class="btn btn-default" type="button" value="Check All with Value" @click="checkAllWithValue" />
    </div>
</template>

<script>
import {store} from "@/store/Report.store";
import DateHelpers from "@/store/DateHelpers";
import Results from "@/components/reports/utils/Results";
import DatePicker from "@/components/utils/DatePicker";
import cdiLocationCache from "@/cache/cdiLocation.cache";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import helpers from "@/store/helpers";

export default {
    name: "ship_paid",
    components: {
        Typeahead,
        DatePicker,
        Results
    },
    data() {
        return {
            state: store.state,
            optionFields: {
                startDate:      {val: DateHelpers.getFirstOfTheMonth(), qsField: '[dates][actual_ship_from]'},
                endDate:        {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]'},
                cdiLocation:    {val: 1, qsField: '[numbers][cdi_location_id]'},
                shipperId:      {val: 0, qsField: '[numbers][t.shipping_co_id]'},
                paid:           {val: 0, qsField: '[shipping_paid]'}
            },
            cache: {
                cdiLocationCache
            }
        }
    },
    methods: {
        runReport: function () {
            store.runReport();
        },
        checkAllWithValue: function() {
            store.customReport('ship_paid', 'flag_all_as_paid', helpers.getQSPairs(this.optionFields)).then(() => store.runReport());
        },
        checkItem: function(el) {
            if (el.type === 'checkbox') {
                let id = el.getAttribute('value');
                let value = (el.checked) ? 1 : 0;
                store.customAPI(`tracker/set_shipping_paid/${ id }`, {'params': value }).then( (res) => {
                    if (res.result) {
                        this.$appStore.successBox('Order Updated');
                    }
                });
            }
        },
        changeFreight: function (el){
           let id = el.getAttribute('id');
            let value = el.value;
            store.customAPI(`tracker/save_freight_cost/${ id }`, {'params[freight_cost]': value  }).then( (res) => {
                if (res.result) {
                    this.$appStore.successBox('Order Updated');
                }
            });
        }
    },
    created() {
        this.$appStore.setTitle(['Shipping Paid'])
        this.$set(store.state.results, this.$options.name, '');
        this.optionFields.cdiLocation.val = this.$appStore.state.user.schedulerLocation;
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
    }
}
</script>

<style scoped>

</style>